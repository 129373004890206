import React from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import Layout from '~/components/Layout'
import { PostsForPageQuery, IGasbyPageProps, IPost } from '~/types'
import SEO from '~/components/SEO'
import PostPreview from '~/components/PostPreview'
import ArticleContainer from '~/components/ArticleContainer'
import Pagination from '~/components/Pagination'
import { formatPostListFromData } from '~/utils/post'
import FilterPanel from '~/components/FilterPanel'

interface IPostListTemplatePageContext {
  currentPage: number
  numPages: number
}

interface IPostListTemplateProps extends IGasbyPageProps {
  data: PostsForPageQuery
  pageContext: IPostListTemplatePageContext
}

const PostListTemplate: React.FC<IPostListTemplateProps> = ({ data, uri, pageContext }) => {
  const posts = formatPostListFromData(data)

  const { currentPage, numPages } = pageContext
  return (
    <Layout uri={uri}>
      <ArticleContainer>
        <FilterPanel></FilterPanel>
        {posts.map((post, index) => (
          <PostPreview post={post} key={index} />
        ))}
        <Pagination
          className="fixed-width mb-2"
          currentPage={currentPage}
          numberOfPages={numPages}
          getPageUriByIndex={n => (n === 1 ? `/blog` : `/blog/page-${n}`)}
        ></Pagination>
      </ArticleContainer>
      <SEO
        title="Блог"
        description="Проповеди, беседы, передачи, стихи, уроки, путевые заметки, аудио и видео материалы, прот. Артемия Владимирова. Официальный блог."
        robots="noindex,follow"
        meta={[
          {
            property: `og:image`,
            content: data.file.childImageSharp.fixed.src,
          },
          {
            property: `og:image:width`,
            content: `1920`,
          },
          {
            property: `og:image:height`,
            content: `1080`,
          },
          {
            property: `og:url`,
            content: `${data.site.siteMetadata.siteUrl}/blog`,
          },
        ]}
      ></SEO>
    </Layout>
  )
}

export default PostListTemplate

export const query = graphql`
query postsForPage($skip: Int!, $limit: Int!, $contentWidth: Int!) {
  allMarkdownRemark(
    sort: {fields: frontmatter___date, order: DESC}
    limit: $limit
    skip: $skip
    filter: {
      frontmatter: {draft: {ne: true}}
      fields: {
        urlPath: {ne: null}
        source: {eq: "posts"}
      }
    }
  ) {
    edges {
      node {
        ...PostDataFragment
      }
    }
  }
  file(relativePath: { eq: "site-cover.jpg" }) {
    childImageSharp {
      fixed(width: 1920, height: 1080, quality: 85) {
        ...GatsbyImageSharpFixed
      }
    }
  }
  site {
    siteMetadata {
      siteUrl
    }
  }
}`